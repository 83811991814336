import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing5/Hero";
import MushroomsList from "../sections/landing5/MushroomsList";

const Mushrooms = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "light",
          align: "center",
          isFluid: true,
          button: "null", // cta, account, null, cart
        }}
        footerConfig={{
          theme: "light",
          style: "style4", //style1, style2
        }}
      >
        <Hero />
        <MushroomsList/>
      </PageWrapper>
    </>
  );
};

export default Mushrooms;
